import React from 'react'
import './Footer.css';

function Footer() {
  return (
    <div className='footer'>
        <p className='copyright-text'>Edited by Joshua Son on 9/20/23 · © Copyright 2023 Joshua Son </p>
  </div>
  )
}

export default Footer